import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator';

const App: React.FC = () => {
  const [all_list, set_all_list] = useState('');
  const [ok_list, set_ok_list] = useState('');
  const [not_ok_list, set_not_ok_list] = useState('');
  const [loading, set_loading] = useState(false);

  useEffect(() => {
    const socket = io('http://cek-backlink.com:8001');
    socket.connect();

    socket.on('update-waiting-list',waiting_list => {
      set_all_list(waiting_list);
    });

    socket.on('update-ok-list', ok => {
      set_ok_list(prev => prev + ok);
    });

    socket.on('update-not-ok-list', not_ok => {
      set_not_ok_list(prev => prev + not_ok);
    });

    socket.on('done', () => {
      set_loading(false);
    });
  }, []);

  const startCleanup = async () => {
    try {
      if (loading) return;
      set_loading(true);
      const uri = '/api/check/';

      set_ok_list('');
      set_not_ok_list('');

      const response = await fetch(uri, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ allLinks: all_list.trim() }),
      });

      const res = await response.json();
      if (!response.ok) throw new Error(res.message);
      alert(res.message);
    } catch (error) {
      alert(error || 'Terjadi kesalahan ketika berkomunikasi dengan server.');
    }
  };

  return (
    <table className='min-w-full'>
      <thead className='bg-gray-50'>
        <tr>
          <th
            scope='col'
            className='whitespace-nowrap py-3.5 pl-4 pr-3 text-md text-center font-semibold text-gray-900 sm:pl-6'
          >
            All Links
          </th>

          <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-md text-center font-semibold text-gray-900'>
            OK
          </th>

          <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-md text-center font-semibold text-gray-900'>
            Not OK
          </th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        <tr>
          <td className='whitespace-nowrap pt-5 pb-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
            <textarea
              rows={28}
              className='w-full resize-none px-4 py-3 border-2 border-indigo-400 rounded'
              onChange={e => set_all_list(e.target.value)}
              value={all_list}
              disabled={loading}
            ></textarea>
          </td>

          <td className='whitespace-nowrap px-3 pt-5 pb-1 text-sm text-gray-900'>
            <textarea
              rows={28}
              className='w-full resize-none px-4 py-3 border-2 border-indigo-400 rounded'
              onChange={e => set_ok_list(e.target.value)}
              value={ok_list}
              disabled={loading}
            ></textarea>
          </td>

          <td className='relative whitespace-nowrap pt-5 pb-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-gray-900'>
            <textarea
              rows={28}
              className='w-full resize-none px-4 py-3 border-2 border-indigo-400 rounded'
              onChange={e => set_not_ok_list(e.target.value)}
              value={not_ok_list}
              disabled={loading}
            ></textarea>
          </td>
        </tr>

        <tr>
          <td className='whitespace-nowrap pb-4 pl-4 pr-6 text-sm font-medium text-gray-900 sm:pl-6' colSpan={3}>
            <button
              type='button'
              className='inline-flex items-center px-4 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full justify-center'
              onClick={startCleanup}
              disabled={loading}
            >
              {loading ? <LoadingIndicator colorScheme='light' /> : 'Start Cleanup'}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default App;
